import React from 'react'

import './OrganizationOnBoarding.scss'

export default class OrganizationOnBoarding extends React.Component {
  render() {
    return (
      <div className="organization-onboarding">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-6 order-md-2">
              <h3 className="show-on-mobile">Organization Onboarding with Frank</h3>
              <div className="video-wrapper">
                <div className="video-iframe">
                  <iframe
                    // src="https://www.youtube.com/embed/YLBcr2z1coQ"
                    src="https://www.youtube.com/embed/videoseries?list=PLIfr-CPk1iVEhrGv3Cb5iRMpJqnkLb_hl"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-1">
              <h3 className="hide-on-mobile">Organization Onboarding with Frank</h3>
              <h4>
                Join Professor Frank Bergdoll, Ovation customer and edtech expert, as he walks through everything you
                need to know to hit the ground running with Ovation in your organization.
              </h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
